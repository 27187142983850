import { Vue, Component } from 'vue-property-decorator';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import programListStore from "@/store/modules/programList";
import { IProgramData } from '@/Model/programModel';
import Sites from './sites/Sites.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';

@Component({
    components: {
        "sites": Sites,
        "bread-crumb":BreadcrumbComponent
    }
})
export default class Program extends Vue {
    public highestRole: number = APP_CONST.MINUS_ONE;
    public isAccountAdmin: boolean = false;
    public programBreadcrumbs: string[] = ['Partner Accounts','Account','Site/Session'];

    /* istanbul ignore next */
    get programDt(){
        return programListStore.programDt;
    }

    /* istanbul ignore next */
    async beforeMount() {
        this.highestRole = Number(APP_UTILITIES.getCookie("highest_role"));
        const programId = Number(APP_UTILITIES.getCookie('program_id'));
        if (programId) {
            programListStore.mutateSitesPayload(JSON.parse(JSON.stringify(APP_CONST.SITES_PAYLOAD)));
            programListStore.mutateSitesPayload({ ...programListStore.currentSitesPayload, id: programId });
            programListStore.getProgramDetailsById(programId);
        }
        this.isAccountAdmin = Boolean(APP_UTILITIES.getCookie("isAccountAdmin"));
    }

    getStatus(status: number) {
        switch (status) {
            case 1:
                return "Active";
            case 2:
                return "Inactive";
            default:
                return "";
        }
    }

    formatShortDate(date: string) {
        return APP_UTILITIES.formatShortDate(date);
    }

    editProgram(programDt: IProgramData){
        this.$router.push('/accounts/editprogram');
    }

}
